import React from 'react';
import pixelVerticalImage from "../images/pixel-vertical-blue.svg"

export default function VerticalPixelImagePocket() {
    return (
        <img
            className="pixel-vertical"
            src={pixelVerticalImage}
            alt="Imagen de un rectángulo en puntos color azul."
        />
    );
}
